import { template as template_5c4d7535bbd2499c9a60d3105e89224c } from "@ember/template-compiler";
const WelcomeHeader = template_5c4d7535bbd2499c9a60d3105e89224c(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
