import { template as template_aed89e404eed452fa9e2029dadf0511e } from "@ember/template-compiler";
const FKLabel = template_aed89e404eed452fa9e2029dadf0511e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
